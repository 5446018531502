import React from "react";

export default function scrollTopButton({ buttonID }) {
  const buttonClicked = () => {
    window.dataLayer.push({
      event: "buttonClicked",
      eventData: {
        buttonID: buttonID,
      },
      // pageType: pageType,
      // destination: destination
    });
  };

  return (
    <div className="scroll-top-button-wrap">
      <a
        className="btn btn-primary text-white btn-lg"
        type="submit"
        href="#home-section-1"
        onClick={buttonClicked}
      >
        Get Free Quote Now
      </a>
    </div>
  );
}
