import React, { useEffect } from "react";

import * as CONST from "../CONSTANTS";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HPZip from "../components/home/zipCode";
import ScrollTopButton from "../components/home/scrollTopButton";

import Calculator from "../images/Calculator_Monochromatic.inline.svg";
import FamilyGroup from "../images/family-group.inline.svg";
import HandDollar from "../images/hand-money-dollar-coin.inline.svg";
import MedicalReport from "../images/medical-report.inline.svg";
import Tick from "../images/tick.inline.svg";

import { initJornaya } from "../components/thirdParty/jornaya";

const IndexPage = () => {
  useEffect(() => {
    initJornaya();
  }, []);

  return (
    <Layout layoutType={CONST.LAYOUT_HOME_PAGE}>
      <Seo title="Home" />

      <div id="home-section-1" className="container home home-section final-expense">
        <div className="row">
          <div className="col-12">
            <h1 className="w-md-50 frontpage-heading">
              Get <span className="text-primary">Final&nbsp;Expense</span>{" "}
              Coverage Today
            </h1>
          </div>
          <div className="col-12 col-md-6 order-2 order-md-1">
            <p className="mb-4">
              Final expense life insurance is a type of{" "}
              <span className="text-primary">
                <b>
                  life insurance specifically designed to cover end-of-life
                  expenses, such as funeral costs.
                </b>
              </span>{" "}
              It generally has lower coverage amounts and premiums than
              traditional life insurance policies, making it{" "}
              <b>more affordable for those with limited incomes.</b> Final
              expense policies can be an important financial safety net for
              loved ones left behind.
            </p>

            <p className="mb-4">
              When you purchase final expense insurance, you are essentially
              pre-paying for your funeral costs. This can be a burden lifted off
              of your family during an already difficult time. And, because
              final expense insurance is generally easy to qualify for, it can
              provide peace of mind knowing that your final expenses are taken
              care of.
            </p>

            <p className="mb-4">
              If you’re considering final expense insurance, be sure to compare
              quotes and policy options from multiple providers to find the best
              coverage for your needs.{" "}
              <b>
                Please complete a few simple steps and we’ll get you on your way
                to coverage in no time!
              </b>
            </p>
          </div>

          <div className="col-12 col-md-6 text-center d-flex align-items-center order-1 order-md-2  mb-4 mb-md-0 ">
            <HPZip />
          </div>
        </div>
      </div>

      <div className="white-wrap bg-white ">
        <div className="container home-section home-examples pt-5 pb-5 mt-5">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <h1 className="section-title">What are some final expenses?</h1>
            </div>
            <div className="col-12 text-center mb-5">
              <Calculator className="mw-100" />
            </div>

            <div className="col-12 col-md-4 text-center">
              <h3 className="mb-2">
                <b>Estate settlement costs</b>
              </h3>
              <p>
                If you have any outstanding debts or final taxes owed, final
                expense insurance can help cover these costs.
              </p>
            </div>

            <div className="col-12 col-md-4 text-center">
              <h3 className="mb-2">
                <b>Funeral costs</b>
              </h3>
              <p>
                This can include everything from the funeral service itself to
                the cost of a casket or urn.
              </p>
            </div>

            <div className="col-12 col-md-4 text-center mb-4">
              <h3 className="mb-2">
                <b>Outstanding medical bills</b>
              </h3>
              <p>
                If you have any unpaid medical bills from treatments received
                prior to your death, final expense insurance can help cover
                these costs.
              </p>
            </div>

            <div className="col-12 text-center">
              <ScrollTopButton buttonID="home-examples-quote" />
            </div>
          </div>
        </div>
      </div>

      <div className="container home-section home-benefits pt-5 pb-5">
        <div className="row text-center gy-4">
          <div className="col-12 text-center mb-4">
            <h1 className="section-title">
              Benefits of final expense insurance
            </h1>
          </div>

          <div className="col-12 col-md-6">
            <div className="benefit-card">
              <div className="icon-wrap">
                <FamilyGroup />
              </div>

              <h3 className="mb-2">Easing the burden on your loved ones</h3>

              <p>
                Final expenses can be a financial burden on your loved ones
                during an already difficult time. Final expense insurance can
                help relieve this burden by pre-paying for your funeral costs.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="benefit-card">
              <div className="icon-wrap">
                <Tick />
              </div>

              <h3 className="mb-2">Quick approval process</h3>

              <p>
                Many final expense policies can be approved within days, giving
                you peace of mind knowing that your final expenses are taken
                care of.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="benefit-card">
              <div className="icon-wrap">
                <MedicalReport />
              </div>

              <h3 className="mb-2">No medical exams required</h3>

              <p>
                Most final expense life insurance policies do not require a
                medical exam, making them easy to qualify for.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="benefit-card">
              <div className="icon-wrap">
                <HandDollar />
              </div>

              <h3 className="mb-2">Affordable premiums </h3>

              <p>
                Final expense life insurance generally has lower coverage
                amounts and premiums than traditional life insurance policies,
                making it more affordable for those with limited incomes.
              </p>
            </div>
          </div>

          <div className="col-12 text-center mt-5">
            <ScrollTopButton buttonID="home-benefits-quote" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
